import { ROUTES } from "const";
import { courseService, learningLiveService } from "libs";

export class ABILITAZIONE30CFUMANIFESTO {

  static async getDashboardCards(courseCode, registrationCode) {
    let cards = this.getFixedCards();

    try {


    } catch (error) { }

    return cards;
  }

  static getFixedCards() {
    return [
      {
        title: "Situazione pagamenti",
        link: ROUTES.MU_COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
    ];
  }

  static getDashboardNotices(courseCode, competitionClass) {
    let notices = [

    ];

    return notices;
  }
}
